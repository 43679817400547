import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import JoinUs from '../components/JoinUs';

const JoinUsPage = () => {
    return (
        <Layout>
            <Helmet
                title="Rejoinez nous"
                meta={[
                    {
                        name: "description",
                        content: "Rejoinez nous, déposez votre candidature ainsi que vos accomplissements et nous reviendrons vers vous"
                    }
                ]}
            />
            <JoinUs />
        </Layout>
    )
}
export default JoinUsPage