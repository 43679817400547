import React from 'react'
import styles from './join.module.css'
import JoinUsSection from './helpers/JoinUsSection';

const JoinUs = () => {
    return (
        <div>
            <section className={`header-parallax ${styles.hero}`}>
                <div className="container">
                    <h1>Rejoignez-nous</h1>
                </div>
            </section>
            <section className={`container ${styles.presentation_section}`}>
                <p>
                    Le Lorem Ipsum est simplement du faux texte employé dans la composition
                    et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard
                    de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla
                    ensemble des morceaux de texte pour réaliser un livre spécimen de police
                </p>
            </section>
            <section className={`container ${styles.joinus_section}`}>
                <JoinUsSection />
            </section>

        </div>
    )
}
export default JoinUs