import React from 'react'
import { Button, Input, Dropdown } from 'semantic-ui-react';
import styles from '../join.module.css'

const CandidateWork = ({ type, handleNextClick, handlePrevClick, handleChange, handleSpecialityChanged }) => {
    return (
        <div>
            {(type === 'Comedien') &&
                <div>
                    <h3>Présentez votre travail de commédien</h3>

                    <h4><label htmlFor="video">Le lien de l'une de vos videos</label></h4>
                    <Input name="video" fluid type="text" id='video' placeholder="Collez le lien de la video ici" onChange={handleChange} />

                </div>}
            {(type === 'Technicien') &&
                <div>
                    <h3>Présentez votre travail de technicien</h3>

                    <h4><lable htmlFor="domain">Selectionnez votre spécialité</lable></h4>
                    <Dropdown
                        placeholder='Selectionnez votre spécialité'
                        fluid
                        name="Specialite"
                        selection
                        options={techniciensDomains}
                        onChange={handleSpecialityChanged}
                    />
                    <h4><label htmlFor="video">L'une de vos videos</label></h4>
                    <Input fluid type="text" onChange={handleChange} id='video' placeholder="Collez le lien de la video ici" name="video" />

                </div>}
            <div className={`field ${styles.submit_container}`}>
                <Button primary className={styles.left_btn} onClick={handlePrevClick}>
                    Précedent
                </Button>
                <Button
                    type="submit"
                    color="red"
                    floated="right"
                    className={`${styles.right_btn} button is-link`}
                    onClick={handleNextClick}
                >
                    Suivant>>
                </Button>
            </div>

        </div>
    )
}
export default CandidateWork
const techniciensDomains = [
    {
        key: "Video",
        text: "Video",
        value: 'Video'
    },
    {
        key: "Son & Audio",
        text: "Son & Audio",
        value: 'Son & Audio'
    }
]