import React, { Component } from 'react'
import JoinUsForm from './joinUsForm';
import { Step } from 'semantic-ui-react';
import { navigate } from "gatsby-link";
import styles from '../join.module.css'
import CandidateWork from './CandidateWork';
import ConfirmInfo from './ConfirmInfo';
function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class JoinUsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            curentStep: 1,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleSpecialityChanged = this.handleSpecialityChanged.bind(this)
        this.handlePrevClick = this.handlePrevClick.bind(this)
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleFormSubmit = e => {
        const { curentStep } = this.state
        if (curentStep === 3) {
            this.setState({ loading: true });
            e.preventDefault();
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({
                    "form-name": 'JoinUs',
                    ...this.state
                })
            })
                .then(() => {console.log("Then");navigate('/thanks')})
                .catch(error => alert(error));
        } else {
            this.setState({
                curentStep: curentStep + 1
            })
        }
    };
    handlePrevClick(){
        const {curentStep} = this.state
        this.setState({
            curentStep: curentStep - 1
        })
    }
    handleSpecialityChanged(e, {value}){
        this.setState({
            Specialite: value
        })
    }
    render() {
        const { curentStep, Type } = this.state
        return (
            <div>
                <h1>Rejoignez-nous</h1>
                <Step.Group ordered fluid>
                    <Step active={curentStep === 1} completed={curentStep > 1}>
                        <Step.Content>
                            <Step.Title>Vos informations</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step active={curentStep === 2} completed={curentStep > 2} >
                        <Step.Content>
                            <Step.Title>Votre travail</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step active={curentStep === 3} completed={curentStep > 3}>
                        <Step.Content>
                            <Step.Title>Confirmation</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
                <div className={styles.step_content}>
                    {(curentStep === 1) && <JoinUsForm handleChange={this.handleChange} handleFormSubmit={this.handleFormSubmit} type={Type}/>}
                    {(curentStep === 2) && <div>
                        <CandidateWork handleChange={this.handleChange} handleNextClick={this.handleFormSubmit} handlePrevClick={this.handlePrevClick} type={Type} handleSpecialityChanged={this.handleSpecialityChanged} />
                    </div>}
                    {(curentStep === 3) && <ConfirmInfo {...this.state} handleNextClick={this.handleFormSubmit} handlePrevClick={this.handlePrevClick} />}
                </div>

            </div>
        )
    }
}
export default JoinUsSection