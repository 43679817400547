import React from 'react'
import styles from '../join.module.css';
import { Button } from 'semantic-ui-react';

const ConfirmInfo = (props) => {
    const { Nom, Telephone, Email, Type, video, Specialite, handleNextClick, handlePrevClick } = props
    return (
        <div className={styles.confirm_info}>
            <h3>Confirmez vos informations</h3>
            <h4>Nom: <span> {Nom} </span></h4>
            <h4>Telephone: <span> {Telephone} </span></h4>
            <h4>Email: <span> {Email} </span></h4>
            <h4>Type: <span> {Type} </span></h4>
            {(Type === "Technicien") &&
                <h4>Spécialite: <span> {Specialite} </span></h4>
            }
            <h4>Video: <span> {video} </span></h4>
            <div className={`field ${styles.submit_container}`}>
                <Button primary className={styles.left_btn} onClick={handlePrevClick}>
                    Précedent
                </Button>
                <Button
                    type="submit"
                    color="red"
                    floated="right"
                    className={`${styles.right_btn}`}
                    onClick={handleNextClick}
                >
                    Envoyer
                </Button>
            </div>
        </div>
    )
}
export default ConfirmInfo