import React from "react";
import { Button } from "semantic-ui-react";
import styles from '../join.module.css'

export default class JoinUsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, loading: false };
  }
  render() {
    const { loading } = this.state;
    const { handleChange, handleFormSubmit } = this.props
    return (
      <section>
        <form
          name="JoinUs"
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleFormSubmit}
          className="ui form"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>
          <div className="field">
            <label htmlFor="nom">Nom/Entreprise:</label>
            <input
              id="nom"
              className="input"
              type={"text"}
              name={"Nom"}
              onChange={handleChange}
              placeholder="Votre nom"
              required={true}
            />
          </div>
          <div className="field">
            <label htmlFor="tel">Votre numéro de téléphone:</label>
            <input
              id="tel"
              className="input"
              type="number"
              name="Telephone"
              onChange={handleChange}
              placeholder="Votre numéro de téléphone"
              required={true}
            />
          </div>
          <div className="field">
            <label htmlFor="mail">Votre adresse mail:</label>
            <input
              id="mail"
              className="input"
              type="email"
              name="Email"
              onChange={handleChange}
              placeholder="Votre adresse mail"
              required={true}
            />
          </div>

          <div className="field">
            <label htmlFor="type">Vous êtes:</label>
            <select
              id="type"
              className="ui search dropdown"
              name="Type"
              required
              onChange={handleChange}
            >
              <option value="">Selectionnez votre fonction</option>
              {functionsTypes.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
            </select>
            <input
              placeholder="Video:"
              className="input"
              type="hidden"
              name="video"
            />
            <input
              className="input"
              type="hidden"
              name="Specialite"
              placeholder="Spécialité:"
            />
          </div>
          <div className={`field ${styles.submit_container}`}>
            <Button
              disabled={loading}
              loading={loading}
              className="button is-link"
              type="submit"
              color="red"
              floated="right"
            >
              Suivant>>
              </Button>
          </div>
        </form>
      </section>
    );
  }
}
const functionsTypes = [
  {
    text: "Commédien",
    value: 'Comedien'
  },
  {
    text: "Technicien",
    value: 'Technicien'
  }
]
